import React, { useState, useEffect, useRef, useCallback } from 'react';
import Chart from 'chart.js/auto';
import { v4 as uuidv4 } from 'uuid';
import logoSmall from './logo-small.svg';
import inputOverlay from './inputoverlay.png';
import tickSound from './ding.mp3';
import Joyride from 'react-joyride';

const BlissList = () => {

    // Default init items in BlissList
    const defaultItems = [
        { id: uuidv4(), name: "↖ Tap [+] to add an activity", category: "Health", checks: new Array(7).fill(false) },
        { id: uuidv4(), name: "← Tap [×] to remove one", category: "Community", checks: new Array(7).fill(false) },
        { id: uuidv4(), name: "→ Tick completed activities", category: "Creativity", checks: new Array(7).fill(false) },
        { id: uuidv4(), name: "↑ Delete these & add your own", category: "Mindfulness", checks: new Array(7).fill(false) }
    ];
          
    // Define currentDay here
    const getCurrentDayIndex = () => {
        const day = new Date().getDay();
        return (day === 0) ? 6 : day - 1; // Map Sunday (0) to the end of the week (6)
    };
    const currentDayIndex = getCurrentDayIndex();
    
    // Store user data BLISSLIST
    const [items, setItems] = useState(() => {
        const storedItems = JSON.parse(localStorage.getItem('blissListItems'));
        return storedItems || defaultItems;
    });
    useEffect(() => {
        localStorage.setItem('blissListItems', JSON.stringify(items));
    }, [items]);

    // Store user data SPIRALNOTES
    const [gratitudeNotes, setGratitudeNotes] = useState(() => {
        const storedNotes = JSON.parse(localStorage.getItem('gratitudeNotes')) || [];
        return Array.isArray(storedNotes) ? storedNotes : [];
    });
    const [newGratitudeNote, setNewGratitudeNote] = useState('');
    useEffect(() => {
        localStorage.setItem('gratitudeNotes', JSON.stringify(gratitudeNotes));
    }, [gratitudeNotes]);

    // Store user data THEME
    const [theme, setTheme] = useState(() => {
        const storedTheme = localStorage.getItem('theme');
        return storedTheme || 'dark'; // Default to dark mode if no theme is stored
    });
    
    // Clear user data
    const clearLocalStorage = () => {
        localStorage.clear();
        setItems(defaultItems);
        setGratitudeNotes({});
    };

    // Basic category setup
    const categories = {
        'Health': { color: '#cdb4db' },
        'Community': { color: '#ffafcc' },
        'Creativity': { color: '#a2d2ff' },
        'Mindfulness': { color: '#d5bdaf' }
    };

    // Add/remove items in BlistList
    const [newItem, setNewItem] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('Health');
    const blissListRef = useRef(null);
    const handleAddItem = () => {
        if (items.length >= 16) {
            alert("Sorry, the list maximum is 16!");
            return;
        }
        if (!newItem.trim()) return;
        const newItemEntry = {
            id: uuidv4(),
            name: newItem.trim(),
            category: selectedCategory,
            checks: new Array(7).fill(false)
        };
        setItems(prevItems => [...prevItems, newItemEntry]);
        setNewItem('');
        playTickSound();
        // Jump to the BlissList section after adding
        if (blissListRef.current) {
            blissListRef.current.scrollIntoView({ behavior: 'auto', block: 'start' });
        }
    };
    const handleInputChange = (e) => {
        setNewItem(e.target.value);
    };
    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);
    };
    const handleRemoveItem = (id) => {
        setItems(prevItems => prevItems.filter(item => item.id !== id));
    };
    const handleCheckChange = (itemId, dayIndex) => {
        if (dayIndex !== currentDayIndex) {
            return; // Prevent changing ticks for non-current days
        }
        setItems(prevItems => prevItems.map(item => {
            if (item.id === itemId) {
                const updatedChecks = [...item.checks];
                if (!updatedChecks[dayIndex]) { // Only allow checking, not unchecking
                    updatedChecks[dayIndex] = true;
                    playTickSound(); // Play sound on tick change
                }
                return { ...item, checks: updatedChecks };
            }
            return item;
        }));    
        playTickSound();
    };        

    // Function for SPIRALNOTES
    const handleAddGratitudeNote = () => {
        if (!newGratitudeNote.trim()) return;
        const newNote = {
            id: uuidv4(),
            content: newGratitudeNote.trim(),
            date: new Date().toLocaleDateString('en-UK', { day: '2-digit', month: '2-digit' })
        };
        setGratitudeNotes(prevNotes => [newNote, ...prevNotes]);
        setNewGratitudeNote('');
    };
    const handleRemoveGratitudeNote = (id) => {
        setGratitudeNotes(prevNotes => prevNotes.filter(note => note.id !== id));
    };

    // Function to reset ticks
    const handleResetTicks = useCallback(() => {
        setItems(prevItems => prevItems.map(item => ({ ...item, checks: new Array(7).fill(false) })));
        localStorage.setItem('lastReset', new Date().toISOString());
    }, []);

    // Function to check if weekly reset is needed
    const checkWeeklyReset = useCallback(() => {
        const lastReset = new Date(localStorage.getItem('lastReset'));
        const now = new Date();
        const monday = new Date(now);
        monday.setDate(now.getDate() - now.getDay() + 1);
        monday.setHours(1, 0, 0, 0); // Set to 1am on Monday

        if (now >= monday && (!lastReset || lastReset < monday)) {
            handleResetTicks();
        }
    }, [handleResetTicks]);
    useEffect(() => {
        checkWeeklyReset();
    }, [checkWeeklyReset]);

    // Balance Check Chart.js setup
    const chartRef = useRef(null);
    const chartInstance = useRef(null);
    useEffect(() => {
        const categoryCompletionData = Object.values(categoryCompletion());
        const chartCtx = chartRef.current.getContext('2d');
        if (chartInstance.current) {
            chartInstance.current.destroy(); // Destroy the existing chart before creating a new one
        }
        chartInstance.current = new Chart(chartCtx, {
            type: 'pie',
            data: {
                labels: Object.keys(categories),
                datasets: [{
                    data: categoryCompletionData,
                    backgroundColor: ['#cdb4db', '#ffafcc', '#a2d2ff', '#d5bdaf'],
                    hoverBackgroundColor: ['#bfa2c9', '#ff9fb8', '#91cbee', '#c7a693'],
                    borderColor: '#282c34',
                    borderWidth: 10
                }]
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: false
                    }
                },
                title: {
                    display: false,
                }
            }
        });
    }, [items]);

    // Balance Check Calculations
    const totalTicks = () => {
        return items.reduce((total, item) => total + item.checks.filter(Boolean).length, 0);
    };
    const categoryCompletion = () => {
        const completion = {};
        let totalTicks = 0;
        Object.values(items).forEach(item => {
            totalTicks += item.checks.filter(Boolean).length;
        });
        Object.keys(categories).forEach(category => {
            const categoryTicks = items.reduce((total, item) => {
                if (item.category === category) {
                    return total + item.checks.filter(Boolean).length;
                }
                return total;
            }, 0);
            completion[category] = totalTicks > 0 ? Math.round((categoryTicks / totalTicks) * 100) : 0;
        });
        return completion;
    };
    const groupedItems = Object.keys(categories).reduce((acc, category) => {
        acc[category] = items.filter(item => item.category === category);
        return acc;
    }, {});

    // BrainDump
    const [brainDumpText, setBrainDumpText] = useState('');
    const [brainDumpProgress, setBrainDumpProgress] = useState(0);
    const brainDumpAudioRef = useRef(null);
    const [inputValue, setInputValue] = useState('');
    const [isFading, setIsFading] = useState(false);
    const handleBlur = () => {
        setIsFading(true);
        setTimeout(() => {
        setInputValue('');
        setIsFading(false);
        }, 2000); // Adjust the duration (in milliseconds) as needed
    };
    const handleBrainDumpChange = (e) => {
        const newText = e.target.value;
        setBrainDumpText(newText);
    
        // Calculate word count
        const wordCount = newText.trim().split(/\s+/).length;
    
        // Calculate progress percentage
        const newProgress = Math.min((wordCount / 300) * 100, 100);
    
        // Update progress bar
        setBrainDumpProgress(newProgress);
        if (newProgress === 100) {
            brainDumpAudioRef.current.play();
        }
    };

    // Joyride Guided Tour
    const [runTour, setRunTour] = useState(false);
    useEffect(() => {
        const hasSeenTour = localStorage.getItem('hasSeenBlissListTour');
        if (!hasSeenTour) {
        setRunTour(true);
        localStorage.setItem('hasSeenBlissListTour', 'true');
        }
    }, []);
    const steps = [
        {
        target: '.tour1',
        disableBeacon: true,
        content: 'This is your BlissList sheet where you can track your daily activities.',
        },
        {
        target: '.tour2',
        content: 'Click + to add a new activity to your BlissList.',
        },
        {
        target: '.tour3',
        content: 'Click the X to remove an activity.',
        },
        {
        target: '.tour4',
        content: 'Tick activities in the current day when completed. (These reset every Monday morning, ready for a new week, so Sunday night is the ideal time for reflection and a balance check)',
        },
        {
        target: '.tour5',
        content: 'Use the menu to explore other areas of the app, like the Brain Dump (flush out unhelpful thoughts) and Spiral Notes (save good-feeling thoughts)',
        }
        // Add more steps as needed
    ];

    // Help/Explainer Popups
    const [showOverlay1, setShowOverlay1] = useState(false);
    const [showOverlay2, setShowOverlay2] = useState(false);
    const [showOverlay3, setShowOverlay3] = useState(false);

    // Sound Effects
    const playTickSound = () => {
        const audio = document.getElementById('tickSound');
        audio.play();
      };

    // Dark/Light Theme Toggle
    function toggleLightMode() {
        const newTheme = theme === 'dark' ? 'light' : 'dark';
        setTheme(newTheme);
        document.body.classList.toggle('light-mode');
        localStorage.setItem('theme', newTheme);
    }
    useEffect(() => {
        if (theme === 'light') {
            document.body.classList.add('light-mode');
        }
    }, [theme]);
    
      
    // UI
    return (
        <div id="BlissList" className="container mt-4" ref={blissListRef}>
            <audio id="tickSound" src={tickSound}></audio>
            <section className="BlissList">
                <Joyride
                    steps={steps}
                    run={runTour}
                    continuous
                    // showSkipButton
                    locale={{
                        last:"Done :)",
                    }}
                    styles={{
                    options: {
                        primaryColor: '#3b73ff', // Button color
                        arrowColor: '#fff56d',
                        backgroundColor: '#fff56d',
                        overlayColor: 'rgba(0, 0, 0, 0.5)',
                        textColor: '#1e2026',
                        // width: 900,
                    },
                    }}
                />
                <table className="table">
                    <thead>
                        <tr>
                            <th className="RemoveItems AddItems tour2">
                                <a href="#EditBox"><button className="btn btn-danger btn-sm">+</button></a>
                            </th>
                            <th className="Activities tour1">
                                <img src={logoSmall} alt="BlissList" style={{ height: '30px', opacity: '1' }} />
                            </th>
                            {['M', 'Tu', 'W', 'Th', 'F', 'Sa', 'Su'].map((day, index) => (
                                <th key={day} style={{
                                    width: '10%',
                                    borderTopLeftRadius: '5px',
                                    borderTopRightRadius: '5px',
                                    backgroundColor: index === currentDayIndex ? '#fff56d' : '#3a4968'
                                }}>
                                    {day}
                                </th>
                            ))}
                            <th className="RemoveItems"> </th>
                        </tr>
                    </thead>
                    {Object.entries(groupedItems).map(([category, items]) => (
                    <tbody key={category}>
                        {items.map(item => (
                            <tr key={item.id}>
                                <td className="RemoveItems tour3">
                                    <button onClick={() => handleRemoveItem(item.id)} className="btn btn-danger btn-sm">×</button>
                                </td>
                                <td className="Activities" style={{
                                    backgroundColor: categories[item.category].color,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxWidth: '100px' // Adjust this value based on your design
                                }}>
                                    {/*categories[item.category].symbol*/}{item.name}
                                </td>
                                {item.checks.map((checked, idx) => (
                                    <td className='tour4' key={idx} onClick={() => handleCheckChange(item.id, idx)} style={{
                                        backgroundColor: checked ? '#7083f5' : 'transparent',
                                        cursor: idx === currentDayIndex ? 'pointer' : 'not-allowed'
                                    }}>
                                        {checked ? '✓' : '◯'}
                                    </td>
                                ))}
                                <td className="RemoveItems"> </td>
                            </tr>
                        ))}
                    </tbody>
                ))}
            </table>
            </section>

            {/* ScoreBoard Section */}
            <section id="ScoreBoard">
                <div className="halfyBox halfyBox1">
                    <h2>Balance Check</h2>
                    <p style={{maxWidth:'570px'}}><strong>You have had {totalTicks()} good moments so far this week.</strong></p>
                    <p style={{maxWidth:'570px',marginBottom:'30px'}}>Are they spread across the four quadrants of wellbeing or could your activities be a little more balanced?</p>
                    <div>
                        <div className="halfyLegend"><span style={{backgroundColor:'#cdb4db',padding:'3px 6px',width:'30px',borderRadius:'20px'}}>&nbsp;&nbsp;&nbsp;</span> Health</div>
                        <div className="halfyLegend"><span style={{backgroundColor:'#ffafcc',padding:'3px 6px',width:'30px',borderRadius:'20px'}}>&nbsp;&nbsp;&nbsp;</span> Community</div>
                        <div className="halfyLegend"><span style={{backgroundColor:'#a2d2ff',padding:'3px 6px',width:'30px',borderRadius:'20px'}}>&nbsp;&nbsp;&nbsp;</span> Creativity</div>
                        <div className="halfyLegend"><span style={{backgroundColor:'#d5bdaf',padding:'3px 6px',width:'30px',borderRadius:'20px'}}>&nbsp;&nbsp;&nbsp;</span> Mindfulness</div>
                    </div>
                </div>
                <div className="halfyBox halfyBox2 halfyChart">
                    <canvas ref={chartRef}></canvas>
                </div>
            </section>

            {/* Brain Dump (Morning Pages) Section */}
            <section id="BrainDump">
                <h2>Brain Dump</h2>

                {/* Help Popup */}
                <p><button className='helpPopup' onClick={() => setShowOverlay1(true)}>?</button> <span style={{color:'#999'}} onClick={() => setShowOverlay1(true)}>Learn about the Brain Dump</span></p>
                {showOverlay1 && (
                <div className='overlayBox'>
                    <div className='overlayInner'>
                        <span onClick={() => setShowOverlay1(false)}>✕</span>
                        <h2>Get unblocked.</h2>
                        <p><strong>Why?</strong><br />Sometimes our heads get blocked up with unhelpful stuff &ndash; worries, shame, looping thoughts &ndash; and we can't get back to a good-feeling place very easily unless we take a big brain dump.</p>
                        <p><strong>How?</strong><br />Just type whatever comes into your head! The yellow progress bar below is set to a full "page" of text, which is the minimum dump you should take each day. But don't stop there if your head is still feeling noisy. Ideally just keep writing until you feel clear mentally.</p>
                        <p><strong>And then?</strong><br />This brain dump will auto-flush everything. Nothing is saved, so it's your chance to say ALL the unsayable things and watch them disappear into nothingness so you can enjoy a little peace of mind... finally.</p>
                    </div>
                </div>
                )}

                <div style={{ position: 'relative', width: '100%',top:'15%' }}>
                    <img
                        src={inputOverlay}
                        alt="Overlay"
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 1,
                            pointerEvents: 'none',
                        }}
                    />
                    <input
                        type="text"
                        value={inputValue}
                        onChange={(e) => {
                            setInputValue(e.target.value);
                            handleBrainDumpChange(e); // Call handleBrainDumpChange to update the progress bar
                        }}
                        onBlur={handleBlur}
                        className={isFading ? 'fade-out' : ''}
                        placeholder="Type here..."
                    />
                    <div className='progressOuter'>
                        <div className='progressInner' style={{
                            width: `${brainDumpProgress}%`
                        }}></div>
                    </div>
                </div>
                <audio ref={brainDumpAudioRef} src={tickSound}></audio>
            </section>

            {/* Spiral Notes Section */}
            <section id="SpiralNotes">
                <h2>Spiral Notes</h2>

                {/* Help Popup */}
                <p><button className='helpPopup' onClick={() => setShowOverlay2(true)}>?</button> <span style={{color:'#999'}} onClick={() => setShowOverlay2(true)}>Learn about Spiral Notes</span></p>
                {showOverlay2 && (
                <div className='overlayBox'>
                    <div className='overlayInner'>
                        <span onClick={() => setShowOverlay2(false)}>✕</span>
                        <h2>Gratitude gets you good!</h2>
                        <p><strong>Why?</strong><br />Even just a few moments of genuine, good-feeling gratitude will begin and fuel an upward spiral! Not every day is 100% amazing, so save notes here to revisit those good feelings.</p>
                        <p><strong>How?</strong><br />Easy! Just think about good-feeling things (big or small, real or imagined) and write a few words about it (e.g. <em>I'm grateful for kitty cuddles! Purrrrr</em>) to read and enjoy later.</p>
                        <p><strong>Want to know more?</strong><br /><a href='https://blisslist.app/why.htm#researchStudies' target='_blank'>Check out the research studies here.</a></p>
                    </div>
                </div>
                )}
                <textarea
                    placeholder="What are you grateful for today?"
                    value={newGratitudeNote}
                    onChange={(e) => setNewGratitudeNote(e.target.value)}
                    style={{ width: '100%', height: '100px' }}
                /><br />
                <button onClick={handleAddGratitudeNote}>Add Note</button>
                <p>&nbsp;</p>
                <div className="notes-list">
                    {gratitudeNotes.map(note => (
                        <div key={note.id} className="note">
                            <p><strong>{note.date}:</strong> {note.content}</p>
                            {/*<button onClick={() => handleRemoveGratitudeNote(note.id)}>Remove</button>*/}
                        </div>
                    ))}
                </div>
            </section>

            {/* Add Items (EditBox Section) */}
            <section id="EditBox">
                <h2>Add good stuff here!</h2>

                {/* Help Popup */}
                <button style={{width:'auto'}} className='helpPopup' onClick={() => setShowOverlay3(true)}>?</button>
                {showOverlay3 && (
                <div className='overlayBox'>
                    <div className='overlayInner'>
                        <span onClick={() => setShowOverlay3(false)}>✕</span>
                        <h2>What builds your wellbeing?</h2>
                        <p>Even just focussing on a few little good-feeling things each day contributes to your upward spiral. And an upward spiral can change your whole experience of life, as well as improving your health.</p>
                        <p><strong>Keep it short</strong><br />12 activities is the recommended maximum, because too many is overwhelming. Just focus on important things that are easy to achieve.</p>
                        <p><strong>Remember...</strong><br />The idea is NOT to forcefully try complete <i>everything</i> every day. Just review the list daily and <i>notice</i> what you do complete. Easy!</p>
                        <p><strong>Stuck for ideas?</strong> Check out our <a href='https://blisslist.app/activities.htm' target='_blank'>list of suggestions</a>.</p>
                    </div>
                </div>
                )}

                <div style={{ position: 'relative', maxWidth: '600px',top:'10%',margin:'0 auto',color:'white' }}>
                    <p><strong>1. Activity name</strong> (1-3 words)...</p>
                    <input type="text" value={newItem} onChange={handleInputChange} placeholder="New Activity" className="form-control" />
                    <p><br/><strong>2. Select category</strong>...</p>
                    <select value={selectedCategory} onChange={handleCategoryChange} className="form-control mt-2">
                        {Object.keys(categories).map(category => (
                            <option key={category} value={category}>{category}</option>
                        ))}
                    </select>
                    <button onClick={handleAddItem} className="btn btn-primary mt-2">3. Add it!</button>
                </div>
            </section>

            {/* HelpBox Section */}
            <section id="HelpBox" style={{maxWidth: '900px',margin:'0 auto',clear:'both'}}>
                <h2>Need Help?</h2>
                <div className="halfyBox">
                    <p><strong>HOW TO USE THIS APP</strong></p>
                    <p><strong style={{color:'#fff56d'}}>The best way to use this app is to [+] add things that both feel good now AND also accumulate improvements over time</strong>, like practicing meditation, eating healthy foods, exercise, quality time with your favourite people, and working on passion projects.</p>
                    <p>Load up your list with 8-12 good things like this and <u>set a time every day</u> to check off any completed activities.</p>
                    <p><i><strong>ProTip:</strong> Try spread activities across the four quadrants of wellbeing — health, community, creativity and mindfulness — because <u>holistic</u> wellbeing leads to bliss</i>.</p>
                    <p>At the end of the week, check your balance and see how you might improve the following week.</p>
                    <p><strong>Note:</strong> The chart should automatically reset on Monday, but if it doesn't, please <u onClick={handleResetTicks}>click here to reset it manually</u>.</p>
                </div>
                <div className="halfyBox">
                    <p><strong>PRIVACY</strong></p>
                    <p>Privacy and simplicity is important, so your BlissList data is stored on your local device ONLY and does not require a login. It is totally safe (as long as it is your own device) because there is no linked personal data and the list is not shared, viewable or accessible by anyone but you!</p>
                    <p>Want to clear ALL your stored info? <a href='/'><u onClick={clearLocalStorage} style={{cursor:'pointer'}}>Click here</u></a>.<br />&nbsp;</p>
                    <div className="helpLink">
                        <p><strong>STILL NEED HELP?</strong></p>
                        <p>For support or information:<br />&rarr; <a href='https://blisslist.app/contact' target='_blank' className="nav-link"><span style={{color:'#ffffff'}}><u>contact us</u></span></a></p>
                    </div>
                </div>
                <div style={{clear:'both'}}>&nbsp;</div>
            </section>

            <div className="navbar-bottom">
                <a href="#BlissList" className="nav-link tour5"><span className="nav-icon">♥</span><span className="nav-text"> BlissList</span></a>
                {/* <a href="#EditBox" className="nav-link"><span className="nav-icon">+</span><span className="nav-text"> Add</span></a> */}
                <a href="#ScoreBoard" className="nav-link"><span className="nav-icon">=</span><span className="nav-text"> Balance Check</span></a>
                <a href="#BrainDump" className="nav-link"><span className="nav-icon">⨂</span><span className="nav-text"> Brain Dump</span></a>
                <a href="#SpiralNotes" className="nav-link"><span className="nav-icon">✎</span><span className="nav-text"> Spiral Notes</span></a>
                {/* <a href="#BlissList" className="nav-link" onClick={toggleLightMode}><span className="nav-icon">◐</span> Theme</a> */}
                <a href="#HelpBox" className="nav-link"><span className="nav-icon">?</span><span className="nav-text"> Help</span></a>
                {/* <a className="nav-link"><span className="nav-icon"><span className="nav-score">{totalTicks()}</span></span> ✓</a> */}
            </div>

        </div> // END #BlissList container
    );
};

export default BlissList;
import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import BlissList from './BlissList';

function App() {

  return (
    <div className="App">
      <header className="App-header">
        <a href="#BlissList"><img src={logo} className="App-logo" alt="logo" /></a>
        <br /><br />
        <a href="#BlissList" style={{fontSize:'18px',fontWeight:'bold',textDecoration:'none',padding:'40px 30px',borderRadius:'80px',backgroundColor:'#3b73ff',color:'white'}}>Start</a>
      </header>
      
      <BlissList />

      <div className="navbar-bottom">
        <a href="#BlissList" className="nav-link tour5"><span className="nav-icon">♥</span><span className="nav-text"> BlissList</span></a>
        {/* <a href="#EditBox" className="nav-link"><span className="nav-icon">+</span><span className="nav-text"> Add</span></a> */}
        <a href="#ScoreBoard" className="nav-link"><span className="nav-icon">=</span><span className="nav-text"> Balance Check</span></a>
        <a href="#BrainDump" className="nav-link"><span className="nav-icon">⨂</span><span className="nav-text"> Brain Dump</span></a>
        <a href="#SpiralNotes" className="nav-link"><span className="nav-icon">✎</span><span className="nav-text"> Spiral Notes</span></a>
        {/* <a href="#BlissList" className="nav-link" onClick={toggleLightMode}><span className="nav-icon">◐</span> Theme</a> */}
        <a href="#HelpBox" className="nav-link"><span className="nav-icon">?</span><span className="nav-text"> Help</span></a>
        {/* <a className="nav-link"><span className="nav-icon"><span className="nav-score">{totalTicks()}</span></span> ✓</a> */}
      </div>
    </div>
  );
}

export default App;